.wrapper {
  color: rgb(34, 12, 12);
}

.grade {
  font-size: 60px;
}

@media (max-width: 578px) {
  .grade {
    font-size: 30px;
  }
}
