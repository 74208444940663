:root {
  --app-height: 100%;
}

a {
  color: #585858;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

.App {
  max-width: 100vw;
  max-height: var(--app-height);
  overflow: hidden;
}

body {
  background-color: #f7f7f7;
  color: #585858;
  margin: 0px;
  padding: 0px;
  font-size: 125%;
}

h2 {
  font-size: 1.2em;
  font-weight: normal;
}

.hidden {
  display: none;
}

.layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

.sky {
  width: 100%;
  flex: 1 1 auto;
  background-color: #bceeff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.game-wrapper {
  box-sizing: border-box;
  font-size: 1em;
  line-height: 1.6em;
  width: 100%;
  background-image: url('../assets/sky.png');
  background-size: contain;
  background-position: top;
  height: 150px;
}

.hide_game > div {
  opacity: 0;
}

.ground {
  flex: 1 1 auto;
  width: 100%;
  background-color: #86c245;
  display: flex;
  align-items: center;
  justify-content: center;
}

h1 {
  -webkit-margin-before: 0;
}

#offline-resources {
  display: none;
}

.endGame-wrapper {
  width: 100vw;
  height: var(--app-height);
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}

.limiter {
  min-width: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 20px;
  box-sizing: border-box;
}

.select-wrapper {
  position: absolute;
  left: 0px;
  top: 0px;
  padding: 0px 20px;
  width: 100%;
  height: var(--app-height);
  box-sizing: border-box;
}

.endGame-wrapper > div {
  -webkit-box-shadow: 0px 0px 37px 0px rgba(37, 0, 255, 0.5);
  -moz-box-shadow: 0px 0px 37px 0px rgba(37, 0, 255, 0.5);
  box-shadow: 0px 0px 37px 0px rgba(37, 0, 255, 0.5);
}

@media (max-width: 768px) {
  h1 {
    font-size: 34px;
  }
  h2 {
    font-size: 35px;
  }
}
