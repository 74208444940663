.wrapper {
  color: #fff;
}

.phrase {
  font-size: 60px;
}

@media (max-width: 578px) {
  .phrase {
    font-size: 30px;
  }
}
