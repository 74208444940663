.diploma {
  min-height: 200px;
  color: #fff;
  padding: 9px;
  width: 100%;
  text-align: center;
  border: 6px solid #0081f8;
  background-color: #0033da;
  z-index: 1;
  margin-bottom: 10px;
}

.wrapper {
  background-color: #0033da;
  border: 6px solid #002a9f;
  box-sizing: border-box;
  height: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.grade,
.score {
  flex: 1;
  border-bottom: 1px solid #fff;
  align-self: center;
  display: flex;
}

.stat {
  align-self: center;
  padding: 8px 0px;
}

.diplomaPhrase {
  font-size: 50px;
  letter-spacing: 10px;
}

.gradePhrase {
  font-weight: 400;
}

.indexHirsh {
  font-size: 24px;
  font-weight: 400;
}

.scoreCount {
  font-size: 36px;
  font-weight: 400;
}

@media (max-width: 578px) {
  .diplomaPhrase {
    font-size: 35px;
    font-weight: 500;
  }

  .diploma {
    margin-bottom: 15px;
  }
}

.cancel_button {
  background-color: transparent;
  border: none;
  font-size: 28px;
  font-family: inherit;
  color: #fff;
  flex: 1;
  padding: 8px 0px;
  position: relative;
  z-index: 3;
}
