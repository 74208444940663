.wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 120px;
  color: #fff;
  min-width: 270px;
  padding: 15px;
  text-align: center;
  border: 6px solid #ffacb9;
  background-color: #ff6d84;
  height: 260px;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.genders {
  height: 100%;
  display: flex;
}

.gender {
  flex: 1;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gender img {
  opacity: 0.8;
  transition: all 0.3s ease;
}

.gender img:hover {
  cursor: pointer;
  opacity: 1;
}

.selectPhrase {
  font-weight: normal;
}
