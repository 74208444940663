.start {
  height: 100px;
  color: #fff;
  width: 100%;
  padding: 9px;
  text-align: center;
  border: 6px solid #ffacb9;
  background-color: #ff6d84;
}

.wrapper {
  background-color: #ff6d84;
  border: 6px solid #f0495f;
  box-sizing: border-box;
  height: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.playPhrase {
  font-weight: normal;
  font-size: 30px;
  letter-spacing: 5px;
  text-align: center;
  width: 100%;
}

@media (max-width: 578px) {
  .playPhrase {
    font-weight: normal;
    font-size: 24px;
  }
}
