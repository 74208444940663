.wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 150px;
  color: #fff;
  padding: 15px;
  text-align: center;
  border: 6px solid #ffacb9;
  background-color: #ff6d84;
  display: flex;
  z-index: 2;
  flex-direction: column;
}

.langs {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
}
.lang {
  font-size: 60px;
  box-sizing: border-box;
  padding: 30px;
  cursor: pointer;
  opacity: 0.8;
}

.lang:hover {
  font-weight: 500;
  opacity: 1;
}

.selectPhrase {
  font-weight: normal;
}
